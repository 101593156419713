.header {
  // margin-top: 85px;

  position: relative;

  .back-video {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -2;

  }

  .overlay {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    background-color: black;
    opacity: 0.7;
  }

  .parent-box {
    height: 100%;
    height: calc(100vh - 85px);
    .row {
      height: 100%;
    }
  }

  .left-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;

    .title {
      color: #fff;
      font-size: 48px;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: -0.96px;
      margin-bottom: 16px;
      font-family: "Inter-SemiBold";
      text-transform: uppercase;
    }

    .commit {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;
      font-family: "Inter-Regular";
    }

    .btn {
      border-radius: 8px;
      background: #e43767;
      padding: 16px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      border: none;
      transition: 0.3s all linear;
      font-family: "Inter-Regular";
      &:hover {
        background-color: #fff;
        color: #e43767;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    // margin-top: 100px;
    padding: 0 4px;

    .parent-box {
      height: 100%;
      height: calc(100vh - 100px);
      .row {
        height: 100%;
      }
    }

    .left-box {
      .title {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -1.44px;
        margin-bottom: 16px;
      }

      .commit {
        font-size: 18px;
        margin-bottom: 40px;
      }
    }
  }
}
