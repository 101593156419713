.section-6 {
  padding: 90px 0;
  background-image: url("/assets/img/bg-section-3.png");

  .title {
    color: white;
    text-transform: uppercase;
    font-family: "Inter-Bold", sans-serif;
    font-size: 36px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 69px;
  }

  .card {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
    transition: 0.5s linear;

    a {
      padding: 6px 0;
      display: flex;
      justify-content: space-between;
      color: #E43767;
      font-family: "Inter-Bold", sans-serif;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      border: none;
    }

    .text {
      color: #1E1E1E;
      font-family: "Inter-Regular", sans-serif;
      font-size: 12px;
      line-height: 18px; /* 150% */
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-body {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .section-6 {
    padding: 90px 25px;

    .title {
      text-align: left;
      width: auto;
      color: white;
      margin-bottom: 48px;
    }

    .card a {
      text-align: left;
    }
  }
}