.services {
  background: #1e1e1e;
  padding: 87px 0;
  .parent-box {
    height: 100%;
  }

  .title {
    color: #e43767;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;
  }

  .commit {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0 100px;
  }

  .services-cards {
    margin-top: 73px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    .card {
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.04);
      height: 100%;
      transition: 0.5s linear !important;

      .card-body {
        padding: 29px 20px 30px 20px;
        .card-title {
          color: #e43767;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin-top: 19px;
          margin-bottom: 5px;
        }
        .card-desc {
          color: #1e1e1e;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          font-family: "Inter-Regular";
        }
      }

      &:hover {
        transform: scale(1.05) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .services {
    padding: 87px 5px;

    .title {
      margin-bottom: 12px;
    }

    .commit {
      padding: 0;
    }

    .services-cards {
      margin-top: 36px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      overflow: auto;

      .card {
        width: 232px;

        .card-body {
          padding: 29px 20px 30px 20px;
          .card-title {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
