.section-4 {
  background-color: #1E1E1E;
  padding: 90px 0;
  .title{
    color: #FFF;
    text-align: center;
    font-family: "Inter-Bold",sans-serif;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 48px;
  }
  .box{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 24px 20px;
    text-align: center;
    height: 248px;
    transition: 0.5s linear !important;
    .box-title{
      color: #E43767;
      text-align: center;
      font-family: "Inter-Bold",sans-serif;
      line-height: 18px;
      margin-top: 22px;
    }
    .box-text{
      color: #1E1E1E;
      text-align: center;
      font-family: "Inter-Medium",sans-serif;
      line-height: 22px;
      margin-top: 10px;
    }

    &:hover{
      transform: scale(1.05) !important;
    }
  }
}
@media (max-width: 768px) {
  .section-4 {
    padding: 90px 37px;
    .title{
      width: auto;
      text-align: center;
      margin-bottom: 37px;
    }
    .box{
      margin-bottom: 20px;
    }
  }
}
