.portfolio {
  background: {
    image: url("../../assets/img/portfolio/bg-portfolio.png");
    position: center center;
    size: cover;
    repeat: no-repeat;
  }

  padding: 80px 0;
  .parent-box {
    height: 100%;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;
  }

  .partners-mobile{
    display: none;
  }

  .partners-desktop {
    margin-top: 50px;
    padding: 0 100px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 30px;
    column-gap: 60px;
    .item {
      width: 100%;
      height: 100%;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
        .black{
          display: none;
        }
        .white{
          display: block;
        }
        &:hover{
          .black{
            display: block;
          }
          .white{
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .portfolio {
    padding: 74px 4px;

    .title {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 12px;
    }

    .partners-desktop{
      display: none;
    }

    .partners-mobile {
      display: grid;
      margin-top: 40px;
      padding: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(9, 1fr);
      row-gap: 0px;
      column-gap: 30px;

      .item {
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        .image-box {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
