.site-top {
  display: flex;
  background-color: red;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 30px;
  .text {
    color: #333;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .text-2 {
    display: none;
  }
}

@media (max-width: 768px) {
  .site-top {
    height: 30px;
    .text {
      display: none;
    }
    .text-2 {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
