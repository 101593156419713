.footer {
  //background: #E43767;
  background-color: #1E1E1E;
  padding: 90px 0 16px 0;
  color: white;

  .brand {
    img {
      width: 150px;
    }
  }

  .brand-text {
    width: 180px;
    color: #FFF;
    font-family: "Inter-Medium", sans-serif;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .social-media {
    display: flex;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: #FFF;
      margin-right: 12px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.10);
      transition: 0.5s linear;
      &:hover{
        transform: scale(1.1);
      }
    }
  }

  .text {
    color: #FFF;
    font-family: "Inter-SemiBold", sans-serif;
    line-height: 20px; /* 125% */
    margin-bottom: 16px;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 12px;
    }

    a {
      color: #FFF;
      font-family: "Inter-Regular", sans-serif;
      line-height: 20px; /* 116.667% */
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid white;
      }
    }
  }

  .social-link {
    //margin-top: 12px;
    display: inline-block;
    color: #FFF;
    font-family: "Inter-Regular", sans-serif;
    line-height: 20px; /* 116.667% */
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid white;
    }
  }

  .office {
    color: #FFF;
    font-family: "Inter-SemiBold", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
  }

  .instagram-video {
    display: flex;
    margin-bottom: 16px;
    a{
      display: block;
      margin-right: 16px;
      text-decoration: none;
      &:last-child{
        margin-right: 0;
      }
    }
    img{
      width: 80px;
    }
  }

  hr {
    margin: 115px 0 16px 0;
    border: 1px solid #ECECEF;
  }

  .digital-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footer {
    background-image: none;
    padding: 40px 45px 16px 45px;

    .text, .brand {
      margin-top: 40px;
    }

    hr {
      margin: 60px 0 16px 0;
    }
    .instagram-video {
      margin-bottom: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      a{
        margin-bottom: 16px;
        width: 50%;
        margin-right: 0;
        text-align: center;
      }
      img{
        width: 90%;
      }
    }
  }
}