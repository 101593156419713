.fact-about-us {
  background-color: #1e1e1e;

  padding: 80px 0;
  .parent-box {
    height: 100%;
  }

  .title {
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 12px;
  }

  .commit {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0 100px;
  }

  .actions {
    margin-top: 48px;
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 10px;
        }
        .numbers-box {
          display: flex;
          align-items: center;
          .number {
            color: #e43767;
            font-size: 36.641px;
            font-weight: 700;
            width: 50px;
          }
          .plus {
            color: #e43767;
            font-size: 36.641px;
            font-weight: 700;
          }
        }
      }
      .action-title {
        margin-top: 7px;
        color: #1e1e1e;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .fact-about-us {
    padding: 74px 4px;

    .title {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 12px;
    }

    .commit {
      font-size: 16px;
      line-height: 20px;
      padding: 0;
    }

    .actions {
      margin: 0 40px;
      margin-top: 40px;
      border-radius: 8px;
      padding: 12px 24px;
      .action {
        margin-bottom: 16px;
        .top {
          img {
            margin-right: 10px;
          }
        }
      }
      .action:last-child(4) {
        margin-bottom: 0;
      }
    }
  }
}
