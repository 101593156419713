@font-face {
  font-family: 'Inter-Regular';
  src: url("./fonts/Inter/Inter-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url("./fonts/Inter/Inter-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url("./fonts/Inter/Inter-SemiBold.ttf") format('truetype');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url("./fonts/Inter/Inter-Bold.ttf") format('truetype');
}