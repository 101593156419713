.section-5 {
  padding: 90px 0;
  background-color: #1E1E1E;

  .title {
    width: 477px;
    color: #FFF;
    font-family: "Inter-Bold", sans-serif;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 48px;
  }

  .box {
    width: 95% !important;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.06);
    padding: 12px 12px 0 12px;
    box-sizing: border-box;
  }

  .box-header {
    height: 222px;
    border-radius: 8px;
    background: #D9D9D9;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .box-footer {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-prev {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: #E43767;
    position: absolute;
    top: -72px;
    left: auto;
    right: 75px;
    background-image: url("../../assets/img/icon-left.svg");
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
      content: '';
    }

    &:hover {
      background-color: white;
      border: 1px solid #E43767;
      background-image: url("../../assets/img/icon-left-active.svg");
    }
  }

  .slick-next {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: #E43767;
    position: absolute;
    top: -72px;
    left: auto;
    background-image: url("../../assets/img/icon-right.svg");
    background-repeat: no-repeat;
    background-position: center center;
    right: 11px;

    &:before {
      content: '';
    }

    &:hover {
      border: 1px solid #E43767;
      background-color: white;
      background-image: url("../../assets/img/icon-right-active.svg");
    }
  }
}

@media (max-width: 768px) {
  .section-5 {
    padding: 90px 37px;

    .title {
      width: 300px;
      margin-bottom: 110px;
    }

    .box {
      width: 100% !important;
    }
  }
}
