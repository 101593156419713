.Loading {
  background-color: hsl(193, 85%, 34%);
  background-color: #ffff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: yellow;
  svg {
    width: 200px;
    color: #0d80a1;
  }
}
