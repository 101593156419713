.section-3 {
  background-image: url("/assets/img/bg-section-3.png");
  padding: 90px 0;
  .phone-img{
    img{
      height: 500px;
    }
  }
  .title{
    width: 380px;
    color: #FFF;
    font-family: "Inter-Bold",sans-serif;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 56px;
  }
  .step-one{
    //width: 484px;
    border-radius: 16px;
    border: 2px solid rgba(0, 0, 0, 0.04);
    background: #FFF;
    box-shadow: 0px 28px 20px 0px rgba(0, 0, 0, 0.08);
    padding: 20px 16px;
    margin-top: 16px;
   transition: 0.5s linear;

    .step-title{
      cursor: pointer;
      color: #E43767;
      font-family: "Inter-Bold",sans-serif;
      line-height: 20px;
    }
    .step-text{
      color: #1E1E1E;
      font-family: "Inter-Regular",sans-serif;
      font-size: 12px;
      line-height: 18px;
      margin-left:60px ;
    }

    &:hover{
      transform: scale(1.05);
    }
  }
  .circle{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF0F4;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 16px;
    color: #E43767;
    text-align: center;
    font-family: "Inter-Bold",sans-serif;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
  }
  .step-two, .step-three{
    margin-top: 32px;
    display: flex;
    align-items: center;
    .text{
      color: #FFF;
      font-family: "Inter-Bold",sans-serif;
      line-height: 20px;
    }
  }
}
@media (max-width: 768px) {
  .section-3 {
    .title{
      width: auto;
      text-align: center;
      font-size: 34px;
      margin-top: 70px;
    }

  }
}
