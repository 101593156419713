@font-face {
  font-family: "Jost";
  src: url("../src/fonts/Jost/static/Jost-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter-Regular",sans-serif;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  height: 20px;
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  background: #E43767;
}
