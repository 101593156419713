$color: #fff;
$height-icon: 20px;
$width-line: 40px;
$height-line: 5px;
$transition-time: 0.3s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

#burger-icon {
  width: $width-line;
  height: $height-icon;
  position: relative;
  display: block;
  margin-bottom: $height-line;

  .burger {
    display: block;
    background: $color;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;

    &.part-1 {
      top: 0;
    }
    &.part-2 {
      top: 50%;
    }
    &.part-3 {
      top: 100%;
    }
  }
  &:hover,
  &:focus {
    .part-1 {
      transform: translateY($height-line / 2 * -1);
      -webkit-transform: translateY($height-line / 2 * -1);
      -moz-transform: translateY($height-line / 2 * -1);
    }
    .part-3 {
      transform: translateY($height-line / 2);
      -webkit-transform: translateY($height-line / 2);
      -moz-transform: translateY($height-line / 2);
    }
  }
  &.active {
    .part-1 {
      transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
    }
    .part-2 {
      opacity: 0;
    }
    .part-3 {
      transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
    }
  }
}
.nohighlight {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
