.form-component {
  background-image: linear-gradient(to bottom, transparent 85%, transparent 85%, #1E1E1E  15%),
  url('/assets/img/bg-section-3.png');
  padding: 83px 0 0 0;
  box-sizing: border-box;

  .parent-element {
    display: flex;
    justify-content: space-between;
    padding: 0 150px 0 100px;
    align-items: center;

    .parent-element-left {
      width: 450px;

      .parent-element-title {
        color: #FFF;
        font-family: "Inter-Bold", sans-serif;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .parent-element-button {
        color: #E43767;
        font-family: "Inter-SemiBold", sans-serif;
        line-height: 18px;
        border-radius: 8px;
        background: #E1D8E9;
        padding: 12px 24px;
        text-decoration: none;
        display: inline-flex;
        //width: 135px;
        transition: 0.5s linear;

        &:hover {
          background-color: white;
        }
      }
    }
  }

  .box {
    height: 200px;
    border-radius: 16px;
    box-shadow: 12px 12px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    background: #FFE8EF;
    display: flex;

    .box-title {
      color: #E43767;
      font-family: "Inter-Bold", sans-serif;
      font-size: 32px;
      line-height: 40px; /* 111.111% */
    }

    .box-text {
      color: #1E1E1E;
      font-family: "Inter-Regular", sans-serif;
      line-height: 20px; /* 142.857% */
      margin-top: 16px;
      text-transform: uppercase;
    }
  }

  .box-left {
    width: 45%;
  }

  .box-right {
    width: 55%;
  }

  .mt-20 {
    margin-top: 20px;
  }

  input {
    width: 50%;
    height: 46px;
    border-radius: 12px;
    border: none;

    &::placeholder {
      color: #B3B6C9;
      font-family: "Inter-Regular", sans-serif;
      font-size: 12px;
      line-height: 14px; /* 116.667% */
    }
  }

  button {
    width: 50%;
    height: 46px;
    border-radius: 12px;
    background: #DC3664;
    border: none;
    color: #FFFFFE;
    font-family: "Inter-Medium", sans-serif;
    font-size: 14px;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
  }
}

@media (max-width: 768px) {
  .form-component {
    background-image: linear-gradient(to bottom, transparent 70%, transparent 70%, #1E1E1E  30%),
    url('/assets/img/bg-section-3.png');
    padding: 83px 22px 20px 22px;

    .parent-element {
      display: block;
      padding: 0 30px;

      .parent-element-left {
        width: auto;
      }

      .parent-element-right {
        text-align: right;

        img {
          transform: translateY(0);
          width: 50%;
        }
      }
    }


    .box {
      flex-wrap: wrap;
      height: auto;
      padding: 45px 20px 90px 20px;

      .box-title {
        text-align: left;
        width: auto;
      }
    }

    .box-left {
      width: 100%;
    }

    .box-right {
      margin-top: 34px;
      width: 100%;
      padding: 0 30px;
    }

    .mobile-form {
      display: block !important;
    }

    input {
      margin-top: 20px;
      width: 100%;
    }

    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
